<template>
  <v-autocomplete v-model="selected" :items="users" :label="searchLabel" :search-input.sync="search" :hide-details="hideDetails" outlined return-object>
    <template v-slot:item="{ item }">
      <v-list-item-avatar v-if="includePhoto">
        <v-img :src="item.avatar || '/img/no.jpg'"></v-img>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>{{ item.text }}</v-list-item-title>
        <v-list-item-subtitle>{{ item.subtext }}</v-list-item-subtitle>
      </v-list-item-content>
    </template>
    <template v-if="includeSelectPhoto" v-slot:selection>
      <v-chip :close="clearable" @click:close="selected = null">
        <v-avatar left>
          <v-img :src="selected.avatar || '/img/no.jpg'"></v-img>
        </v-avatar>
        {{ selected.text }}
      </v-chip>
    </template>
    <template v-if="clearable && selected && selected.value !== ''" v-slot:append-outer>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon @click="clear" style="margin-top:-5px">
            <v-icon>fal fa-times-circle</v-icon>
          </v-btn>
        </template>
        <span>Clear Search</span>
      </v-tooltip>
    </template>
    <template v-for="(_, scopedSlotName) in $scopedSlots" v-slot:[scopedSlotName]="slotData">
      <slot :name="scopedSlotName" v-bind="slotData"></slot>
    </template>
    <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
      <slot :name="slotName"></slot>
    </template>
  </v-autocomplete>
</template>
<script>
import { ref, watch } from '@vue/composition-api'
// import { $feathers } from '../../storeLocal/feathers'
export default {
  props: {
    searchLabel: {
      type: String,
      default: 'GreatScots Search'
    },
    valueField: {
      type: String,
      default: '_id'
    },
    includePhoto: {
      type: Boolean,
      default: false
    },
    includeSelectPhoto: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    personFilter: {
      type: Array,
      default: () => {
        return [ 'Faculty', 'Staff', 'Student' ]
      }
    },
    filter: {
      type: Object,
      default: () => {
        return {}
      }
    },
    value: {
      type: [Object, String]
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    nameFormatLastFirst: {
      type: Boolean,
      default: false
    },
    includeBoxNumber: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => { return [] }
    }
  },
  setup (props, { root, emit }) {
    const selected = ref(props.value || { name: { first: '', last: '' }, person: '', value: '' })
    const users = ref([])
    const search = ref('')

    watch(() => props.value, async (value) => {
      if (typeof (value) === 'object' && value && 'value' in value && value.value != null && value.value !== '') {
        if (JSON.stringify(props.value) === JSON.stringify(selected.value)) return
        if (props.valueField === '_id') {
          const data = await root.$feathers.service('directory/people').get(value.value)
          users.value = [{
            text: (props.nameFormatLastFirst ? data.name.last + ', ' + data.name.first : data.name.first + ' ' + data.name.last),
            subtext: data.person + (props.includeBoxNumber && data.location && data.location.box ? ' - ' + data.location.box : ''),
            avatar: data.photo,
            value: data[props.valueField] || data.pidm
          }]
          selected.value = users.value[0]
        }
      } else if (value !== '' && value != null) {
        // value is the value field; look up the person based on this field
        const { data } = await root.$feathers.service('directory/people').find({ query: { [props.valueField]: value } })
        if (data.length > 0) {
          users.value = [{
            text: (props.nameFormatLastFirst ? data[0].name.last + ', ' + data[0].name.first : data[0].name.first + ' ' + data[0].name.last),
            subtext: data[0].person + (props.includeBoxNumber && data[0].location && data[0].location.box ? ' - ' + data[0].location.box : ''),
            avatar: data[0].photo,
            value: data[0][props.valueField] || data[0].pidm
          }]
          selected.value = users.value[0]
        }
      } else if (value == null) {
        selected.value = null
        users.value = []
      }
    })

    watch(search, () => {
      if (search.value == null || typeof (search.value) === 'object') return
      if (search.value === '') {
        users.value = []
        return
      }
      let query = { person: { $in: props.personFilter }, active: true, $limit: 20 }
      if (props.nameFormatLastFirst) {
        query.$sort = { 'name.last': 1, 'name.first': 1 }
      } else {
        query.$sort = { 'name.first': 1, 'name.last': 1 }
      }
      for (let l in props.filter) {
        query[l] = props.filter[l]
      }
      query.$select = [ 'name', 'person', 'photo', props.valueField ]
      if (props.includeBoxNumber) query.$select.push('location')
      if (search.value.trim() !== '') {
        if (search.value.search(',') > 0) {
          let temp = search.value.split(',')
          query['name.last'] = { $regex: temp[0].trim(), $options: 'i' }
          query['name.first'] = { $regex: temp[1].trim(), $options: 'i' }
        } else if (search.value.search(' ') > 0) {
          let temp = search.value.split(' ')
          query['name.first'] = { $regex: temp[0].trim(), $options: 'i' }
          query['name.last'] = { $regex: temp[1].trim(), $options: 'i' }
        } else {
          query.$or = [
            { 'name.last': { $regex: search.value.trim(), $options: 'i' } },
            { 'name.first': { $regex: search.value.trim(), $options: 'i' } }
          ]
        }
        root.$feathers.service('directory/people').find({ query }).then(({ data }) => {
          users.value = []
          for (let i = 0; i < data.length; i++) {
            users.value.push({
              text: (props.nameFormatLastFirst ? data[i].name.last + ', ' + data[i].name.first : data[i].name.first + ' ' + data[i].name.last),
              subtext: data[i].person + (props.includeBoxNumber && data[i].location && data[i].location.box ? ' - ' + data[i].location.box : ''),
              avatar: data[i].photo,
              value: data[i][props.valueField] || data[i].pidm
            })
          }
          if (selected.value && 'value' in selected.value) {
            // If we have a user selected, make sure that user is always in the list otherwise they won't show up in the selection anymore
            if (users.value.filter(({ value }) => value === selected.value.value).length === 0) {
              users.value.push(selected.value)
            }
          }
        })
      }
    })

    watch(selected, (val) => {
      if (selected.value == null) return
      emit('input', val)
      emit('select', val)
    })

    function clear () {
      users.value = []
      search.value = ''
      selected.value = null
      emit('clear')
      emit('input', null)
    }

    return {
      selected,
      users,
      search,
      clear
    }
  }
}
</script>
